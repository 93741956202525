import * as React from "react"
import SharedHeader from "../components/general/components/SharedHeader"
import MaintenancePage from "../components/robot/pages/MaintenancePage"


// markup
const Transaction = (props) => {
  return (
    <React.Fragment>
      <SharedHeader />
      <MaintenancePage />
    </React.Fragment>
  )
}

export default Transaction

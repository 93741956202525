import React from "react";
import { connect } from "react-redux";
import { website_maintenanceImg } from "../../../images";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import Layout from "../../general/components/Layout";

// markup

const RobotPage = (props) => {
  // const location = useLocation();
  return (
    <React.Fragment>
      <Layout>
        <div className="px-4">
          <div className="flex justify-center items-center my-3">
            <img
              src={website_maintenanceImg}
              className="-mt-8 object-contain w-full"
              style={{ height: 700 }}
            />
          </div>
        </div>
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
};
export default connect(mapStateToProps, mapDispatchToProps)(RobotPage);
